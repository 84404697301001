import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
// import Services from "../components/Services"
// import Jobs from "../components/Jobs"
import Projects from '../components/Projects';
// import Blogs from "../components/Blogs"
import Bio from '../components/Bio';
import SEO from '../components/SEO';

// import Skills from "../components/Skills"

const Index = ({ data }) => {
  const {
    allStrapiProjects: { nodes: projects },
    // allStrapiBlogs:{ nodes:blogs },
  } = data;
  return (
    <Layout>
      <SEO
        title="Alex Policastro | Designer & Developer"
        url="https:///www.alexpolicastro.com/"
        description="Personal website & portfolio of Alex Policastro. UI/UX Designer & Frontend Web Developer living in San Diego, CA."
        keywords="Designer, Developer, UX/UI, Graphic Designer, Portfolio, Website"
      />
      <Hero />
      <div role="main">
        <div
          data-sal="slide-up"
          data-sal-duration="1500"
          data-sal-delay="300"
          data-sal-easing="easeInOutSine"
        >
          <Bio short />
        </div>
        {/* <Skills top /> */}
        {/* <Services /> */}
        <div
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="easeInOutSine"
        >
          <Projects
            projects={projects}
            title="featured projects"
            showLink
          />
        </div>
      </div>

      {/* <Jobs /> */}
      {/* <Blogs blogs={blogs} title="latest articles" showLink /> */}
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.shape({}),
};

Index.defaultProps = {
  data: [],
};

export default Index;

export const query = graphql`
  {
    allStrapiProjects(filter: { featured: { eq: true } }) {
      nodes {
        slug
        id
        description
        url
        title
        order
        category
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
    #  allStrapiBlogs(sort: {fields: date, order: DESC}, limit: 3) {
    #   nodes {
    #     slug
    #     content
    #     desc
    #     date(formatString: "MMM Do, YYY")
    #     title
    #     category
    #     image {
    #       childImageSharp {
    #         fluid {
    #           ...GatsbyImageSharpFluid
    #         }
    #       }
    #     }
    #   }
    # }
  }
`;
