import React from 'react';
import PropTypes from 'prop-types';
// import Image from "gatsby-image"
import classNames from 'classnames';
import { Link } from 'gatsby';
import {
  FaHatWizard,
  FaLaptopCode,
  FaHiking,
  FaMugHot,
} from 'react-icons/fa';
import SocialLinks from '../constants/socialLinks';
import profile from '../assets/hero-img-alex.png';
import resume from '../assets/alex-policastro-resume-2020.pdf';

import '../css/bio.scss';

const Bio = ({ short }) => {
  return (
    <section
      className={classNames({
        'bio-area': true,
        short,
      })}
    >
      <img
        src={profile}
        className="hero-img"
        alt="Alex Policastro"
        title="Alex"
      />
      <div className="container">
        <article className="bio">
          {short && (
            <div
              data-sal="fade"
              data-sal-duration="900"
              data-sal-delay="100"
              data-sal-easing="easeInOutSine"
            >
              <h2>Hello, I&apos;m Alex.</h2>
              <p>
                {' '}
                I am a UI/UX Designer & Frontend Web Developer living
                in San Diego, CA. Design & development are my passions
                and I use them in tandem to push myself to create
                great-looking and functional products.
              </p>
            </div>
          )}
          {!short && (
            <>
              <FaHatWizard className="job-icon" />
              <h2>I&apos;m Alex Policastro.</h2>
              <br />
              <div
                data-sal="fade"
                data-sal-duration="900"
                data-sal-delay="100"
                data-sal-easing="easeInOutSine"
              >
                <h3>Pixel Pusher & Code Junkie</h3>
                <p>
                  I am a UI/UX Designer & Frontend Web Developer
                  living in San Diego, CA. Design & development are my
                  passions and I use them in tandem to push myself to
                  create great-looking and functional products. I’m
                  self-motivated and take pride in learning new
                  methods, always pushing myself to discover what I
                  don&apos;t yet know.
                </p>
                <br />
              </div>
              <div
                data-sal="fade"
                data-sal-duration="900"
                data-sal-delay="100"
                data-sal-easing="easeInOutSine"
              >
                <FaLaptopCode className="job-icon" />
                <h3>Work </h3>
                <p>
                  I have been a Sr. Interactive Designer for Paradeigm
                  for over 5 years, working directly with clients on
                  their web projects. In addition, I have been the
                  lead UI/UX Designer / Frontend Developer for
                  PageBuilder. While managing the development team, we
                  created the website building platform PageBuilder
                  for clients to easier launch and edit their
                  websites.
                </p>
                <br />
              </div>
              <div
                data-sal="fade"
                data-sal-duration="900"
                data-sal-delay="100"
                data-sal-easing="easeInOutSine"
              >
                <FaHiking className="job-icon" />
                <h3>Philosophy</h3>
                <p>
                  I create things to make people&apos;s lives better!
                  At my core, I am a helper. My goal is to do
                  everything with a positive attitude and to help my
                  clients achieve their vision.
                </p>
                <br />
              </div>
              <div
                data-sal="fade"
                data-sal-duration="900"
                data-sal-delay="100"
                data-sal-easing="easeInOutSine"
              >
                <FaMugHot className="job-icon" />
                <h3>Personal</h3>
                <p>
                  I was born in New York, grew up in Wisconsin, and
                  spent the last 10 years in California. I live in
                  North Park with my wife Dominique and cat Luna. When
                  not working on a computer, I use my hands to do
                  woodworking using my CNC machine. I love tropical
                  tiki drinks, pizza, board games, and spending time
                  with friends.
                </p>
                <br />
              </div>
              <div
                data-sal="fade"
                data-sal-duration="900"
                data-sal-delay="100"
                data-sal-easing="easeInOutSine"
              >
                <h3>Find Me</h3>
                <SocialLinks styleClass="bio-links" />
                <br />
                <h3>Resume</h3>
                <a
                  rel="noreferrer"
                  className="btn"
                  target="_blank"
                  href={resume}
                  download
                >
                  Download
                </a>
              </div>
            </>
          )}
        </article>
        {short && (
          <Link
            to="/about"
            className={classNames({
              btn: true,
              'center-btn': true,
              outlined: true,
            })}
          >
            learn more about me
          </Link>
        )}
      </div>
    </section>
  );
};

Bio.propTypes = {
  short: PropTypes.bool,
};
Bio.defaultProps = {
  short: false,
};
export default Bio;
