import React from 'react';
import Title from './Title';
import '../css/hero.scss';

const Hero = () => {
  return (
    <header
      className="hero"
      data-sal="slide-down"
      data-sal-delay="300"
      data-sal-easing="easeInOutQuart"
      data-sal-duration="1000"
    >
      <div className="section-center hero-center">
        <article className="hero-info">
          <Title
            title="Alex Policastro"
            subTitle="Designer & Developer"
            h1
          />
        </article>
      </div>
    </header>
  );
};

export default Hero;
